import { Box, VStack } from "@chakra-ui/react";

// Import the GitHub icon
import { FaGithub } from "react-icons/fa";
import EmbedSection from "./sections/EmbedSection";
import { useEffect } from "react";

const Embed = () => {
  useEffect(() => {
    // Set the chakra-ui-color-mode to light in the localStorage
    localStorage.setItem("chakra-ui-color-mode", "light");
  }, []);

  return (
    <Box position="fixed" w="100vw" h="100vh" overflow="auto"
    backgroundImage="linear-gradient(120deg, #6363f854 0%, #ffffff 22%, #ffffff 72%, #e8acff78 100%)"
    >
      <VStack
        align="center"
        justify="center"
        px="40px"
        style={{
          overflow: "auto",
          position: "absolute",
          top: 0,
          left: 0,
          paddingTop: "200px",
          paddingBottom: "200px",
          width: "100%",
          // height: "100%",
          minHeight: "min-content",
          // backgroundImage:
          //   "linear-gradient(120deg, #FFDEE9 0%, #ffffff 28%, #ffffff 72%, #ffdee9 100%)",
          // backgroundColor: "#FFDEE9",
        }}
      >
        <a
          href="https://github.com/integration-os"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            // Make the github icon bigger and position it to the top right of the page also it should remain fixed

            position: "fixed",
            top: "10px", // Adjust the top position as needed
            right: "10px", // Adjust the right position as needed
            fontSize: "40px", // Adjust the font size as needed
            color: "#000", // Adjust the color as needed
          }}
        >
          <FaGithub size={40} />
        </a>
        <EmbedSection />
      </VStack>
    </Box>
  );
};

export default Embed;
