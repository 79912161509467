import { useAuthKit } from "@integrationos/authkit";

export const useClickOpenEmbedUx = () => {
  const { open } = useAuthKit({
    token: {
      url: process.env.REACT_APP_EVENT_TOKEN_URL as string
    },
    baseUrl: process.env.REACT_APP_EVENT_BASE_URL as string,
    title: "Integrations",
  });
  const trigger = () => {
    open();
  };

  return {
    trigger,
  };
};
