import { HStack, VStack, Text, Box } from "@chakra-ui/react";
import { CopyBlock, tomorrowNightBlue } from "react-code-blocks";

interface CodeblockSnippetProps {
  code?: string;
  language?: string;
  type?: "client" | "server";
}

const customCodeBlockStyle = {
  borderRadius: "0 0 0.375rem 0.375rem",
  fontFamily: "menlo",
  padding: "0 0 0 0",
  fontSize: "12px",
  margin: "0 0 0 0",
  height: "280px"
};

const customTheme = {
  ...tomorrowNightBlue,
  backgroundColor: "#1c2736",
}

const CodeblockSnippet = ({
  code,
  language,
  type = "client",
}: CodeblockSnippetProps) => {
  return (
    <VStack w="100%" spacing="0" boxShadow="2xl" h="280px">
      <HStack borderTopRadius="md" bg="#060c1f" h="40px" w="100%" p="5">
        <Text fontWeight='medium' color="white" fontSize='sm'>{type === "client" ? "Client" : "Server"} side</Text>
      </HStack>
      <Box w="100%">
        <CopyBlock
          customStyle={customCodeBlockStyle}
          theme={customTheme}
          language={language}
          showLineNumbers={true}
          text={code}
          codeBlock
          wrapLines
        />
      </Box>
    </VStack>
  );
};

export default CodeblockSnippet;
