import {
  Box,
  Button,
  HStack,
  Heading,
  SimpleGrid,
  Tag,
  TagLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import CodeblockSnippet from "../components/CodeblockSnippet";
import TYPES from "../types";
import { useClickOpenEmbedUx } from "../hooks/useClickOpenEmbedUx";

const clientCode = `import { useAuthKit } from "@integrationos/authkit";

const { open } = useAuthKit({
  token: {
    url: "https://api.your-company-name.com/authkit-token",
    headers: {},
  },
  onSuccess: (connection) => {},
  onError: (error) => {},
  onClose: () => {},
});`;

const serverCode = `import { AuthKitToken } from "@integrationos/authkit-node";

app.post("/authkit-token", async (request, response) => {
  const authKitToken = new AuthKitToken("sk_live_1234");

  const token = await authKitToken.create({
    group: "meaningful-id", // a meaningful identifier (i.e., organizationId)
    label: "Friendly Label" // a human-friendly label (i.e., organizationName)
  });

  response.send(token);
});`;

const EmbedSection = () => {
  
  const { trigger } = useClickOpenEmbedUx();

  const handleClick = () => {
    window.open(TYPES.ROUTES.NPM, "_blank");
  }

  return (
    <VStack w="100%" h="100%" align="center" justify="center">
      <Tag
        size="lg"
        borderRadius="full"
        border="1px"
        borderColor="gray.100"
        background="transparent"
        p="3"
      >
        <TagLabel>
          <HStack spacing="2">
            <Text fontWeight="medium" color="gray.700">
              First time using IntegrationOS?
            </Text>

            <a href={TYPES.ROUTES.DOCS} target="blank">
              <span style={{}}>
                <HStack spacing="0.5" cursor="pointer">
                  <Text fontWeight="bold" color="purple.500">
                    Start here
                  </Text>
                  <ArrowForwardIcon color="purple.500" fontSize="xl" />
                </HStack>
              </span>
            </a>
          </HStack>
        </TagLabel>
      </Tag>
      <VStack maxW="800px" w="100%" pt="7">
        <Heading textAlign="center" fontSize="6xl" color="black">
          Launch integrations within your application using AuthKit
        </Heading>
        <Text
          fontWeight="medium"
          textAlign="center"
          fontSize="xl"
          color="gray.600"
          pt="4"
        >
          Use our drop-in component to create a better experience for your users
          that guides them through authorizing any integration IntegrationOS
          supports.
        </Text>
        <HStack spacing="4" pt="7">
          <Button
            onClick={trigger}
            bg="purple.500"
            _hover={{
              bg: "purple.400",
            }}
            fontWeight="semibold"
            variant="solid"
            size="lg"
            borderRadius="lg"
          >
            Add Integration
          </Button>
          <Button
            onClick={handleClick}
            borderRadius="lg"
            rightIcon={<ArrowForwardIcon color="black" fontSize="xl" />}
            color="black"
            borderColor="gray.100"
            fontWeight="semibold"
            variant="outline"
            size="lg"
          >
            View on NPM
          </Button>
        </HStack>
      </VStack>
      <SimpleGrid
        maxW="1270px"
        minChildWidth="450px"
        w="100%"
        spacing="50px"
        pt="12"
      >
        <Box>
          <CodeblockSnippet
            code={clientCode}
            type="client"
            language="typescript"
          />
        </Box>
        <Box>
          <CodeblockSnippet
            code={serverCode}
            type="server"
            language="typescript"
          />
        </Box>
      </SimpleGrid>
    </VStack>
  );
};

export default EmbedSection;
