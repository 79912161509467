import { extendTheme, ThemeConfig } from '@chakra-ui/react';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({
  colors: {
    gray: {
      900: '#1F2023',
      800: '#353639',
      700: '#4c4d4f',
      600: '#626365',
      500: '#79797b',
      400: '#8f9091',
      300: '#a5a6a7',
      200: '#bcbcbd',
      100: '#d2d2d3',
      50: '#F0F3F9',
    },
    green: {
      900: '#007d3e',
      800: '#009248',
      700: '#00a652',
      600: '#00bb5d',
      500: '#00d067',
      400: '#1ad576',
      300: '#33d985',
      200: '#4dde95',
      100: '#ccf6e1',
      50: '#e6faf0',
    },
    greenAlpha: {
      900: '#007d3e92',
      800: '#00924880',
      700: '#00a65264',
      600: '#00bb5d48',
      500: '#00d06736',
      400: '#1ad57624',
      300: '#33d98516',
      200: '#4dde9508',
      100: '#ccf6e106',
      50: '#e6faf004',
    },
    navy: {
      900: '#0d0d15',
      800: '#100f19',
      700: '#12121d',
      600: '#151422',
      500: '#171726',
      400: '#31303f',
      300: '#484755',
      200: '#5f5e6a',
      100: '#76757f',
      50: '#8d8c95',
    },
    navyAlpha: {
      900: '#0d0d1580',
      800: '#100f1980',
      700: '#12121d80',
      600: '#15142280',
      500: '#17172680',
      400: '#31303f80',
      300: '#48475580',
      200: '#5f5e6a80',
      100: '#76757f80',
      50: '#8d8c9580',
    },
    blue: {
      900: '#324199',
      800: '#3a4cb3',
      700: '#4257cc',
      600: '#4b62e6',
      500: '#536dff',
      400: '#647cff',
      300: '#758aff',
      200: '#8799ff',
      100: '#dde2ff',
      50: '#eef0ff',
    },
    blueAlpha: {
      900: '#32419980',
      800: '#3a4cb364',
      700: '#4257cc4c',
      600: '#4b62e64b',
      500: '#536dff53',
      400: '#647cff44',
      300: '#758aff33',
      200: '#8799ff22',
      100: '#dde2ff10',
      50: '#eef0ff06',
    },
    red: {
      900: '#992700',
      800: '#b32e00',
      700: '#cc3400',
      600: '#e63b00',
      500: '#ff4100',
      400: '#ff541a',
      300: '#ff6733',
      200: '#ff7a4d',
      100: '#ffd9cc',
      50: '#ffece6',
    },
    redAlpha: {
      900: '#99270080',
      800: '#b32e0064',
      700: '#cc34004c',
      600: '#e63b0042',
      500: '#ff410036',
      400: '#ff541a24',
      300: '#ff673316',
      200: '#ff7a4d08',
      100: '#ffd9cc06',
      50: '#ffece604',
    },
    orange: {
      900: '#ff4f00',
      800: '#ff6119',
      700: '#ff7233',
      600: '#ff844d',
      500: '#ff9566',
      400: '#ffa780',
      300: '#ffb999',
      200: '#ffcab3',
      100: '#ffdccc',
      50: '#ffede6',
    },
    orangeAlpha: {
      900: '#ff4f0080',
      800: '#ff611964',
      700: '#ff72334c',
      600: '#ff844d42',
      500: '#ff956636',
      400: '#ffa78024',
      300: '#ffb99916',
      200: '#ffcab308',
      100: '#ffdccc06',
      50: '#ffede604',
    },
    pink: {
      900: '#ff0066',
      800: '#ff1a80',
      700: '#ff3399',
      600: '#ff4cb3',
      500: '#ff66cc',
      400: '#ff80e6',
      300: '#ff99ff',
      200: '#ffb3ff',
      100: '#ffccff',
      50: '#ffe6ff',
    },
    pinkAlpha: {
      900: '#ff006680',
      800: '#ff1a8064',
      700: '#ff33994c',
      600: '#ff4cb342',
      500: '#ff66cc36',
      400: '#ff80e624',
      300: '#ff99ff16',
      200: '#ffb3ff08',
      100: '#ffccff06',
      50: '#ffe6ff04',
    },
    yellow: {
      900: '#ffd600',
      800: '#ffe81a',
      700: '#fff133',
      600: '#fff84d',
      500: '#fffd66',
      400: '#ffff80',
      300: '#ffff99',
      200: '#ffffb3',
      100: '#ffffcc',
      50: '#ffffe6',
    },
    yellowAlpha: {
      900: '#ffd60080',
      800: '#ffe81a64',
      700: '#fff1334c',
      600: '#fff84d42',
      500: '#fffd6636',
      400: '#ffff8024',
      300: '#ffff9916',
      200: '#ffffb308',
      100: '#ffffcc06',
      50: '#ffffe604',
    },
    purple: {
        500: '#6433D5',
        400: '#7F4BFF',
    },

    black: '#1F2023',
    white: '#f8f9fc',
  },
  fonts: {
    body: 'Inter',
    heading: 'Inter',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 600,
    semibold: 600,
    bold: 800,
    extrabold: 800,
    black: 800,
  },
  styles: {
    global: () => ({
      '*': {
        fontSize: '13px',
        letterSpacing: '0.4px',
      },
      body: {
        fontFamily: 'Inter',
        color: 'gray.700',
        background: 'white',
      },
    }),
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },
  components: {
    Input: {
      variants: {
        outline: () => ({
          field: {
            fontSize: '14px',
            borderColor: 'gray.100',
            '&::placeholder': {
              color: 'gray.500',
            },
          },
        }),
      },
    },
    Select: {
      variants: {
        outline: () => ({
          field: {
            fontSize: '14px',
            borderColor: 'gray.100',
            '&::placeholder': {
              color: 'gray.500',
            },
          },
        }),
      },
    },
    Button: {
      baseStyle: {
        borderRadius: '4px',
        fontWeight: '400',
        lineHeight: '0'
      },
      variants: {
        solid: {
          color: 'white',
        },
        gray: {
          color: 'gray.300',
          bg: 'whiteAlpha.200',
          fontSize: 'sm',
          fontWeight: 'semibold',
          _hover: {
            color: 'white',
            bg: 'whiteAlpha.300',
          },
        },
        'gray-thin': {
          color: 'gray.300',
          // bg: "whiteAlpha.200",
          p: '2',
          fontSize: 'sm',
          fontWeight: 'normal',
          maxHeight: '25px',
          size: 'sm',
          _hover: {
            color: 'white',
            bg: 'whiteAlpha.300',
          },
        },
      },
    },
    Menu: {
      baseStyle: () => ({
        list: {
          borderColor: 'gray.50',
        },
        item: {
          '&:hover': {
            background: 'gray.50',
          },
          '&:focus': {
            background: 'gray.50',
          },
        },
      }),
    },
    Modal: {
      sizes: {
        '6xl': {
          dialog: {
            maxW: '1070px',
          },
        },
      },
    },
    Tooltip: {
      baseStyle: () => ({
        background: 'gray.100',
        color: 'gray.700',
        border: '1px solid',
        borderColor: 'gray.100',
        boxShadow: '1px 0 0 2px rgba(0, 0, 0, 0.1)',
        fontWeight: '400',
        borderRadius: '7px',
        fontSize: 'xs',
      }),
    },
  },

  config,
});

export default theme;
